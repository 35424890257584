import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    ListItemDecorator,
    ModalClose,
    ModalDialog,
    ModalOverflow,
    Radio,
    RadioGroup,
    Tab,
    TabList,
    TabPanel,
    Tabs,
    Typography
} from "@mui/joy"
import AccountIcon from "@mui/icons-material/AccountCircle"
import HRIcon from "@mui/icons-material/HeartBroken"
import SettingsIcon from "@mui/icons-material/Settings"
import {Field, FieldMetaState, Form} from "react-final-form"
import setFieldData from "final-form-set-field-data"
import {Mutator} from "final-form"
import InputMask from "react-input-mask"
import {format, parseISO} from "date-fns"
import Session from 'supertokens-auth-react/recipe/session';
import AutoSave from "./AutoSave"
import JoyDatePicker from "./JoyDatePicker"
import WorkoutIcon from "./WorkoutIcon"
import {EditUser, useGetSelfQuery, useUpdateSelfMutation} from "../app/services/userApi"
import {ProfileDialogLoading} from "./Loading";
import {useTranslation} from "react-i18next";

const validate = (values: EditUser) => {
    const errors: Partial<Record<keyof EditUser, string>> = {}
    if (!values.name) {
        errors.name = "form_validation__name_empty"
    }
    if (!values.weight) {
        errors.weight = "form_validation__weight_empty"
    }
    if (values.weight && ((values.weight as number) < 30 || (values.weight as number) > 300)) {
        errors.weight = "form_validation__weight_invalid"
    }
    if (!values.height) {
        errors.height = "form_validation__height_empty"
    }
    if (values.height && ((values.height as number) < 50 || (values.height as number) > 250)) {
        errors.height = "form_validation__height_invalid"
    }
    // if (birthdate && validator.date(birthdate)) {
    //     errors.birthdate = validator.date(birthdate) as string
    // }
    if (!values.birthdate) {
        errors.birthdate = "form_validation__birthdate_empty"
    }
    if (!values.cyclingFtp) {
        errors.cyclingFtp = "form_validation__cycling_ftp_empty"
    }
    if (!values.cyclingLthr) {
        errors.cyclingLthr = "form_validation__cycling_lthr_empty"
    }
    if (!values.runningPace) {
        errors.runningPace = "form_validation__running_pace_empty"
    }
    if (!values.runningLthr) {
        errors.runningLthr = "form_validation__running_lthr_empty"
    }
    if (!values.swimmingPace) {
        errors.swimmingPace = "form_validation__swimming_pace_empty"
    }
    if (values.cyclingFtp && (values.cyclingFtp < 50 || values.cyclingFtp > 500)) {
        errors.cyclingFtp = 'form_validation__cycling_ftp_invalid';
    }
    if (values.cyclingLthr && (values.cyclingLthr < 80 || values.cyclingLthr > 220)) {
        errors.cyclingLthr = 'form_validation__cycling_lthr_invalid';
    }
    if (values.runningLthr && (values.runningLthr < 80 || values.runningLthr > 220)) {
        errors.runningLthr = 'form_validation__running_lthr_invalid';
    }
    if (values.runningPace && (timeToNum(values.runningPace) < 245 || timeToNum(values.runningPace) > 959)) {
        errors.runningPace = 'form_validation__running_pace_invalid';
    }
    if (values.swimmingPace && (timeToNum(values.swimmingPace) < 100 || timeToNum(values.swimmingPace) > 499)) {
        errors.swimmingPace = 'form_validation__swimming_pace_invalid';
    }
    return errors
}

const timeToNum = (value: string | number | null | undefined): number => {
    const val = value ? `${value}` : '0';
    return parseInt(val.replace(/[.:]/g, ''));
}

const getDurationObj = (src: number | string) => {
    const hours = Math.floor(+src / 3600);
    const minutes = Math.floor((+src - (hours * 3600)) / 60);
    const seconds = Math.floor(+src - (hours * 3600 + minutes * 60));
    return {
        hours,
        minutes,
        seconds,
    };
}

const timeToString = (value: number | null, mode: number): string | null => {
    const strValue = String(value);

    if (mode === 1 && !value) {
        return '00';
    }

    if (mode === 1 && strValue.length < 2) {
        return '0' + strValue;
    }

    if (mode === 3) {
        return null;
    }

    return value ? strValue : '0';
}


const durationObjToString = (duration: any, mode: number = 1): string => {
    return Object.keys(duration)
        .filter((key) => !key.startsWith('__'))
        .map((key) => {
            if ((mode === 2 || mode === 3) && key === 'hours') {
                return timeToString(duration[key], mode);
            }
            return timeToString(duration[key], 1);
        })
        .filter((value => !!value))
        .join(':');
}

const durationToString = (duration: number, mode: number = 1): string => {
    const durationObj = getDurationObj(duration);
    return durationObjToString(durationObj, mode);
}


const getDurationInSeconds = (duration: string): number => {
    if (!duration || duration === '') {
        return 0;
    }
    let mappedTime: Array<number> = [0];
    const timeParts = duration.split(':');
    if (timeParts.length === 1) {
        console.error(`Function 'getDurationInSeconds' get invalid input variable – '${duration}'. Check input value, please.`);
    }
    if (timeParts.length === 2) {
        mappedTime = timeParts
            .map((timeUnit, index) => {
                switch (index) {
                    case 0: {
                        return +timeUnit * 60;
                    }
                    case 1: {
                        return +timeUnit;
                    }
                    default:
                        return 0;
                }
            });
    }
    if (timeParts.length === 3) {
        mappedTime = timeParts
            .map((timeUnit, index) => {
                switch (index) {
                    case 0: {
                        return +timeUnit * 3600;
                    }
                    case 1: {
                        return +timeUnit * 60;
                    }
                    case 2: {
                        return +timeUnit;
                    }
                    default:
                        return 0;
                }
            });
    }
    return mappedTime.reduce((acc, unit) => acc + unit, 0);
}

const FormErrorText = ({meta}: { meta: FieldMetaState<any> }) => {
    const {t, i18n} = useTranslation("profile");
    return (
        <FormHelperText
            sx={{
                visibility: (t(meta.error) || meta.submitError) && meta.touched ? "visible" : "hidden",
                height: "1rem",
                mb: 0.5,
            }}
        >
            {t(meta.error) || meta.submitError}
        </FormHelperText>
    )
}

const SexRadioWrapper = ({input}: { input: any }) => {
    const {t, i18n} = useTranslation("profile");
    return (
        <Radio {...input} label={t("common_form_field__gender_option_" + (input.value as "MALE" | "FEMALE"))}
               sx={{mb: 1.5}}/>
    )
}

const connectGarmin = async () => {
    const {jwt} = await Session.getAccessTokenPayloadSecurely();
    const form = document.createElement("form");
    form.setAttribute("action", "https://login.trisystems.ai/oauth2/garmin/login");
    form.setAttribute("method", "POST");
    const input = document.createElement("input");
    input.setAttribute("name", "access_token");
    input.setAttribute("type", "hidden");
    input.setAttribute("value", jwt);
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
}

const ProfileDialog = ({screen}: { screen?: "mobile" | "desktop" }) => {
    const {isLoading, error, data} = useGetSelfQuery()
    const [updateSelf] = useUpdateSelfMutation()
    const {t} = useTranslation("profile");
    if (isLoading) {
        return <ProfileDialogLoading screen={screen}/>
    }
    if (error) {
        return <div>error..</div>
    }
    if (!data) {
        return <div>unknown..</div>
    }
    const save = (values: EditUser) => {
        const editUser: EditUser = {...values!};
        if (values.runningPace) {
            editUser["runningPace"] = getDurationInSeconds(values.runningPace.toString() || '0');
        }
        if (values.swimmingPace) {
            editUser["swimmingPace"] = getDurationInSeconds(values.swimmingPace.toString() || '0');
        }
        updateSelf({editUser})
    }
    const initialValues: EditUser = {
        ...data,
        runningPace: (data?.runningPace ? durationToString(data!.runningPace, 3) : null) as unknown as number,
        swimmingPace: (data?.swimmingPace ? durationToString(data!.swimmingPace, 3) : null) as unknown as number,
    }
    return (
        <ModalOverflow>
            <ModalDialog
                layout={screen === "mobile" ? "fullscreen" : "center"}
                color="neutral"
                size="lg"
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
                sx={{width: "50vw"}}
            >
                <ModalClose/>
                <Typography
                    id="alert-dialog-modal-title"
                    component="h2"
                    startDecorator={<SettingsIcon/>}
                >
                    {t("dialog_title")}
                </Typography>
                <Divider/>
                <Form<EditUser>
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={save}
                    mutators={{setFieldData: (setFieldData as Mutator<EditUser, Partial<EditUser>>)}}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Tabs aria-label="tabs" defaultValue={0} sx={{py: 2}}>
                                <TabList>
                                    <Tab>
                                        <ListItemDecorator>
                                            <AccountIcon/>
                                        </ListItemDecorator>
                                        <Box sx={{display: {xs: "none", md: "block"}}}>{t("dialog_tab__common")}</Box>
                                    </Tab>
                                    <Tab>
                                        <ListItemDecorator>
                                            <WorkoutIcon sportType="CYCLING"/>
                                        </ListItemDecorator>
                                        <Box sx={{display: {xs: "none", md: "block"}}}>{t("dialog_tab__cycling")}</Box>
                                    </Tab>
                                    <Tab>
                                        <ListItemDecorator>
                                            <WorkoutIcon sportType="RUNNING"/>
                                        </ListItemDecorator>
                                        <Box sx={{display: {xs: "none", md: "block"}}}>{t("dialog_tab__running")}</Box>
                                    </Tab>
                                    <Tab>
                                        <ListItemDecorator>
                                            <WorkoutIcon sportType="SWIMMING"/>
                                        </ListItemDecorator>
                                        <Box sx={{display: {xs: "none", md: "block"}}}>{t("dialog_tab__swimming")}</Box>
                                    </Tab>
                                </TabList>
                                <TabPanel value={0}>
                                    <Box
                                        sx={{
                                            py: 4,
                                            display: "grid",
                                            gridTemplateColumns: {
                                                xs: "1fr",
                                                lg: "2fr 2fr",
                                            },
                                            gap: 2.5
                                        }}
                                    >
                                        <Field name="name">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("common_form_field__fullname_label")}</FormLabel>
                                                    <Input {...input}
                                                           required
                                                           size="lg"
                                                           placeholder={t("common_form_field__fullname_placeholder")}
                                                           error={(t(meta.error) || meta.submitError) && meta.touched}
                                                    />
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="birthdate">
                                            {({input, meta}) => (
                                                <Box>
                                                    <JoyDatePicker
                                                        label={t("common_form_field__birthdate_label")}
                                                        value={parseISO(input.value)}
                                                        onChange={(newValue: Date | null) => {
                                                            return (newValue === null)
                                                                ? input.onChange(newValue)
                                                                : input.onChange(format(newValue, "yyyy-MM-dd"))
                                                        }}
                                                    />
                                                    <FormErrorText meta={meta}/>
                                                </Box>
                                            )}
                                        </Field>
                                        <Field name="height">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("common_form_field__height_label")}</FormLabel>
                                                    <Input
                                                        {...input}
                                                        required
                                                        size="lg"
                                                        error={(t(meta.error) || meta.submitError) && meta.touched}
                                                        endDecorator={t("common_form_field__height_decorator_kg")}
                                                    />
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="weight">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("common_form_field__weight_label")}</FormLabel>
                                                    <Input
                                                        {...input}

                                                        required
                                                        size="lg"
                                                        error={(t(meta.error) || meta.submitError) && meta.touched}
                                                        endDecorator={t("common_form_field__weight_decorator_kg")}
                                                    />
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        {/*<Field name="gender">*/}
                                        {/*    {({input, meta}) => (*/}
                                        <FormControl>
                                            <FormLabel>{t("common_form_field__gender_label")}</FormLabel>
                                            <RadioGroup name="gender" onChange={() => props.handleSubmit()}>
                                                <Field name="gender" type="radio" value="MALE"
                                                       component={SexRadioWrapper}/>
                                                <Field name="gender" type="radio" value="FEMALE"
                                                       component={SexRadioWrapper}/>
                                            </RadioGroup>
                                        </FormControl>
                                        {/*)}*/}
                                        {/*</Field>*/}
                                        <FormControl>
                                            <FormLabel>{t("common_form_field__devices_label")}</FormLabel>
                                            <Button
                                                onClick={() => connectGarmin()}>{t("common_form_button_device__connect_garmin")}</Button>
                                        </FormControl>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={1}>
                                    <Box
                                        sx={{
                                            py: 4,
                                            display: "grid",
                                            gridTemplateColumns: {
                                                xs: "1fr",
                                                lg: "1.2fr 0.8fr",
                                            },
                                            gap: 5
                                        }}
                                    >
                                        <Field name="cyclingFtp">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("cycling_form_field__ftp_label")}</FormLabel>
                                                    <InputMask
                                                        {...input}
                                                        mask="999"
                                                    >
                                                        {/* @ts-ignore */}
                                                        {() =>
                                                            <Input
                                                                required
                                                                size="lg"
                                                                error={(t(meta.error) || meta.submitError) && meta.touched}
                                                                startDecorator={<WorkoutIcon
                                                                    sportType="CYCLING"/>}
                                                                endDecorator={t("cycling_form_field__ftp_decorator")}
                                                            />
                                                        }
                                                    </InputMask>
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Typography
                                            level="body-md">{t("cycling_form_field__ftp_helper_text")}</Typography>
                                        <Field name="cyclingLthr">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("cycling_form_field__lthr_label")}</FormLabel>
                                                    <InputMask
                                                        {...input}
                                                        mask="999"
                                                    >
                                                        {/* @ts-ignore */}
                                                        {() =>
                                                            <Input
                                                                required
                                                                size="lg"
                                                                error={(t(meta.error) || meta.submitError) && meta.touched}
                                                                startDecorator={<HRIcon/>}
                                                                endDecorator={t("cycling_form_field__lthr_decorator")}
                                                            />
                                                        }
                                                    </InputMask>
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Typography
                                            level="body-md">{t("cycling_form_field__lthr_helper_text")}</Typography>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={2}>
                                    <Box
                                        sx={{
                                            py: 4,
                                            display: "grid",
                                            gridTemplateColumns: {
                                                xs: "1fr",
                                                lg: "1.2fr 0.8fr",
                                            },
                                            gap: 5
                                        }}
                                    >
                                        <Field name="runningPace">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("running_form_field__pace_label")}</FormLabel>
                                                    <InputMask
                                                        {...input}
                                                        mask="99:99"
                                                    >
                                                        {/* @ts-ignore */}
                                                        {() =>
                                                            <Input
                                                                required
                                                                size="lg"
                                                                error={(t(meta.error) || meta.submitError) && meta.touched}
                                                                startDecorator={<WorkoutIcon sportType="RUNNING"/>}
                                                                endDecorator={t("running_form_field__pace_decorator")}
                                                            />
                                                        }
                                                    </InputMask>
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Typography
                                            level="body-md">{t("running_form_field__pace_helper_text")}</Typography>
                                        <Field name="runningLthr">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("running_form_field__lthr_label")}</FormLabel>
                                                    <InputMask
                                                        {...input}
                                                        mask="999"
                                                    >
                                                        {/* @ts-ignore */}
                                                        {() =>
                                                            <Input required
                                                                   size="lg"
                                                                   error={(t(meta.error) || meta.submitError) && meta.touched}
                                                                   startDecorator={<HRIcon/>}
                                                                   endDecorator={t("running_form_field__lthr_decorator")}
                                                            />
                                                        }
                                                    </InputMask>
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Typography
                                            level="body-md">{t("running_form_field__lthr_helper_text")}</Typography>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={3}>
                                    <Box
                                        sx={{
                                            py: 4,
                                            display: "grid",
                                            gridTemplateColumns: {
                                                xs: "1fr",
                                                lg: "1.2fr 0.8fr",
                                            },
                                            gap: 5
                                        }}
                                    >
                                        <Field name="swimmingPace">
                                            {({input, meta}) => (
                                                <FormControl>
                                                    <FormLabel>{t("swimming_form_field__pace_label")}</FormLabel>
                                                    <InputMask
                                                        {...input}
                                                        mask="99:99"
                                                    >
                                                        {/* @ts-ignore */}
                                                        {() =>
                                                            <Input required
                                                                   size="lg"
                                                                   error={(t(meta.error) || meta.submitError) && meta.touched}
                                                                   startDecorator={<WorkoutIcon sportType="SWIMMING"/>}
                                                                   endDecorator={t("swimming_form_field__pace_decorator")}
                                                            />
                                                        }
                                                    </InputMask>
                                                    <FormErrorText meta={meta}/>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Typography
                                            level="body-md">{t("swimming_form_field__pace_helper_text")}</Typography>
                                    </Box>
                                </TabPanel>
                            </Tabs>
                            <AutoSave setFieldData={props.form.mutators.setFieldData} save={save}/>
                        </form>
                    )}
                </Form>
            </ModalDialog>
        </ModalOverflow>
    )
}

export default ProfileDialog
